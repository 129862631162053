<template lang="pug">
  .widget-select-date
    appDatepicker(
      label="Choose date"
      :value="value"
      :class="{'active': value}"
      @input="selectDate"
      @change="selectDate"
      @clear="selectDate(undefined)"
      no-title
      :min="getToday"
    )
      template(v-slot:activator)
        span.calendar-icon
</template>

<script>
import Dayjs from 'dayjs';

export default {
  name: 'SelectDate',

  props: {
    value: String
  },

  computed: {
    getToday() {
      return Dayjs().format('YYYY-MM-DD');
    },
  },

  methods: {
    async selectDate(value) {
      if (this.value === value) return
      if (!value) value = undefined
      this.$emit('input', value)
    }
  },

  components: {
    appDatepicker: () => import('@/components/global/fields/appDatepicker')
  }
}
</script>

<style lang="scss">
.widget-select-date {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .app-datepicker {
    min-width: initial;
    max-width: initial;
    margin: 5px;

    &.active {
      .calendar-icon {
        transition: all 0.15s ease-in-out;
        background: url("../../assets/img/ui/calendar-black.svg") no-repeat;
      }
    }

    .calendar-icon {
      min-width: 15px;
      min-height: 15px;
      margin-right: 5px;
      background-size: contain;
      transition: all 0.15s ease-in-out;
      background: url("../../assets/img/ui/calendar-grey.svg") no-repeat;
    }

    .close-icon {
      right: -8px;
      top: -5px;
    }
  }
}
</style>

